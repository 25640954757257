.adBannerContainer {
  width: 99%;
  height: 8%;
  align-self: flex-end;
  overflow: hidden;
}

.adStatsContainer {
  background-color: rgba(0, 0, 0, 0.857);
  width: 100%;
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  padding: 6%;
  box-sizing: border-box;
}

.adBannerContainerTP {
  width: 100%;
  height: 8%;
  align-self: flex-start;
  overflow: hidden;
}

.adStatsContainerTP {
  background-color: rgba(0, 0, 0, 0.857);
  width: 100%;
  height: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  box-sizing: border-box;
}

.adInterstitielContainer {
  background-color: rgba(0, 0, 0, 0.857);
  width: 90%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: 5%;
}

.adLandscapeContainer {
  background-color: rgba(0, 0, 0, 0.857);
  width: 52%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
}

.adStats {
  max-width: 100%;
  max-height: 100%;
}

.adBanner {
  width: 100%;
}

.adInterstitiel {
  max-width: 100%;
  max-height: 100%;
}

.adLandscape {
  max-width: 98%;
  max-height: 80%;
}
