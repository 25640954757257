@use './../base';

.publicAndOptionsContainer {
  display: flex;
  width: 98vw;
  justify-content: space-around;
  flex-wrap: wrap;
}

.optionContainer {
  width: 450px;
  padding: 20px;
}

.rightOptionsContainer {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.checkBoxContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 450px;
}

.descriptionItem {
  font-size: 12px;
  font-family: 'RobotoRegular';
  color: base.$grey-color;
  &.limitedWidth {
    max-width: 500px;
  }
}

.titleInput {
  font-size: 14px;
  font-family: 'RobotoRegular';
}

.postalCodes {
  list-style: none;
  box-sizing: border-box;
  width: 450px;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.postalCodes li {
  width: 100px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid base.$tp-color;
  font-family: 'RobotoLight';
  font-size: 14px;
  line-height: 2;
  background-color: white;
}

.postalCodes button {
  width: 25px;
  height: 25px;
  color: white;
  background-color: base.$tp-color;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  @extend .genericClickable;
}

.budgetContainer {
  display: flex;
  justify-content: space-between;
  color: base.$grey-color;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 2px;
  font-weight: 600;
}

.budgetHelperContainer {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.total {
  font-size: 32px;
  text-align: end;
  margin: 0;
}

@media screen and (max-width: 1650px) {
  // .publicAndOptionsContainer {
  //   width: 75vw;
  // }
  .rightOptionsContainer {
    width: 450px;
  }
}
