.App {
  text-align: center;
}

@font-face {
  font-family: 'NobelBold';
  src: local('NobelBold'), url('./assets/fonts/Nobel-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NobelDtlBold';
  src: local('NobelDtlBold'), url('./assets/fonts/Nobel-Dtl-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NobelDtlLight';
  src: local('NobelDtlLight'), url('./assets/fonts/Nobel-Dtl-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NobelItalic';
  src: local('NobelItalic'), url('./assets/fonts/Nobel-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'NobelRegular';
  src: local('NobelRegular'), url('./assets/fonts/Nobel-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBlackItalic';
  src: local('RobotoBlackItalic'), url('./assets/fonts/Roboto-Black-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBlack';
  src: local('RobotoBlack'), url('./assets/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBoldItalic';
  src: local('RobotoBoldItalic'), url('./assets/fonts/Roboto-Bold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoItalic';
  src: local('RobotoItalic'), url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLightItalic';
  src: local('RobotoLightItalic'), url('./assets/fonts/Roboto-Light-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: local('RobotoLight'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMediumItalic';
  src: local('RobotoMediumItalic'), url('./assets/fonts/Roboto-Medium-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: local('RobotoMedium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoThinItalic';
  src: local('RobotoThinItalic'), url('./assets/fonts/Roboto-Thin-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoThin';
  src: local('RobotoThin'), url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
}
