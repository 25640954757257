@use './../base';

.containerUploadAd {
  width: 400px;
  height: 250px;
  border: 1px solid base.$grey-color;
}

.inputEnabled::file-selector-button {
  // a var we can change with js, or brandColor by default
  background: var(--button-color, base.$brand-color);
  color: white;
  width: 120px;
  height: 30px;
  border-style: none;
  border-radius: 5px;
  font-family: 'RobotoBold';
  font-size: 12px;
  margin: 10px 10px 10px 0;
  @extend .genericClickable;
}
