@use './../base';

.campaignDate {
  font-style: italic;
  margin: 0;
}

.tableMedia {
  max-height: 50px;
  max-width: 150px;
}

.couponTable {
  height: 70px;
  border: 3px solid base.$brand-color;
  box-sizing: border-box;
  width: 90vw;
  margin: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'RobotoRegular';
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}

.endContainer {
  width: 90vw;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.total div {
  border: 3px solid base.$brand-color;
  border-left: none;
  box-sizing: border-box;
  line-height: 55px;
  width: 79px;
  font-size: 14px;
}

.total :first-child {
  border-left: 3px solid base.$brand-color;
  font-weight: bold;
}

.total :last-child {
  color: base.$brand-color;
  font-weight: bold;
}
