@use './../base';

.closeTutoContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.openTutoContainer {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.textNeedHelp {
  font-size: 20px;
  font-weight: bold;
  color: base.$brand-color;
  margin-right: 10px;
}
