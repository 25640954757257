@use './../base';

.accountColumns {
  text-align: center;
  width: 500px;
  margin-top: 20px;
}

.password {
  font-size: 12px;
  font-family: 'RobotoRegular';
  margin-top: 5px;
  margin-bottom: 10px;
}

.address {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}
