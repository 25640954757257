@use '../base';

.homeContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'RobotoMedium';
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  min-height: 100vh;
}

.newAccount {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: base.$brand-color;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoRegie {
  height: 110px;
  margin-top: 30px;
}

.forgotPassword {
  font-size: 12px;
  border-style: none;
  background-color: transparent;
  color: base.$brand-color;
  margin-top: 10px;
  @extend .genericClickable;
}

.passwordSpec {
  font-size: 12px;
  font-family: 'RobotoRegular';
  text-align: left;
  width: 400px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.cgv {
  font-family: 'RobotoBold';
  color: white;
  line-height: 4;
  margin-left: 5px;
}

.containerLangageButton {
  margin-bottom: 30px;
}

.containerLangageButton button {
  border-style: none;
  background-color: inherit;
  @extend .genericClickable;
}
