$font-stack: Helvetica, sans-serif;

$white-color: white;
$primary-color: #f7f8fc;
$secondary-color: #65bcbe;
$brand-color: #64dcbe;
$tp-color: #ff730f;
$grey-color: grey;
$red-color: red;
$green-color: green;

body {
  // font: 100% $font-stack;
  background-color: $primary-color;
}

* {
  transition: all 0.1s ease-out;
}

// to disable arrows in input number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.genericClickable {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    filter: brightness(0.8);
  }
}

.brandClickable {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: adjust-color($color: $brand-color, $lightness: 20%);
  }
}

.formError {
  color: red;
  font-size: 12px;
  font-family: 'RobotoLight';
  margin: 10px 0px 10px 0px;
}

.formInfo {
  color: $brand-color;
  font-size: 12px;
  font-family: 'RobotoLight';
  padding-top: 5px;
  width: 250px;
  text-align: center;
}

.titleStep {
  font-size: 26px;
  font-family: 'NobelDtlBold';
  text-align: center;
}

h3 {
  color: $brand-color;
  font-family: 'RobotoBold';
  font-size: 18px;
}
