@use './../base';

.historyContainer {
  display: flex;
  width: 80vw;
  margin-left: 65px;
}

.historyContainer p {
  margin: 30px 5px 0px 0px;
}

.sum {
  font-size: 30px;
  color: base.$brand-color;
  font-family: 'RobotoBold';
}

.creditDots {
  flex: 1;
  border-bottom: 4px dotted black;
  margin: 0px 10px 3px;
}

.MuiTooltip-popper {
  transition: none;
}
