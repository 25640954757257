@use './../base';

.recapLine {
  display: flex;
}

.dots {
  flex: 1;
  margin: 0 10px 3px;
}

.couponPrice {
  min-width: 40px;
  text-align: right;
  font-weight: bold;
  font-size: 17px;
}
