@use '../base';

.importMedia {
  width: 90vw;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 60px;
}

.importMedia + article {
  width: 450px;
}

.choseFormat {
  flex: 1;
  margin-left: 60px;
  max-width: 60vw;
}

.overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bottomContainer {
  text-align: right;
  width: 100%;
}
