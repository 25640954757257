@use './../base';

.campaignList {
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.newCampaign {
  width: 150px;
  height: 150px;
  background-color: whitesmoke;
  border-radius: 10px;
  border: 2px solid base.$grey-color;
  font-family: 'RobotoRegular';
  font-size: 80px;
  @extend .genericClickable;
}

.newCampaignForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
}

.newCampaignForm p {
  margin: 10px 0 0 0;
}

.errorCampaign {
  font-size: 12px;
  color: red;
  text-align: center;
  margin: 0;
}

.emptyList {
  color: base.$brand-color;
  font-weight: bold;
  font-size: 18px;
  margin-right: 20px;
  width: 300px;
  text-align: center;
}
