@use './../base';

.deleteCampButton {
  width: 50px;
  height: 50px;
  background-color: base.$brand-color;
  border-style: none;
  border-radius: 10px;
  align-self: flex-end;
  margin-top: -70px;
  margin-right: 20px;
  @extend .genericClickable;
}

.annonceContainer {
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.recapTitle {
  width: 100%;
  line-height: 75px;
  background-color: base.$brand-color;
  color: white;
  text-align: center;
  font-family: 'RobotoBold';
  margin: 0 0 40px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.33);
  margin-top: 20px;
}

.newAdButtons {
  align-self: flex-start;
  height: 450px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.emptyAdButtons {
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin-top: 40px;
}

.dateModalContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.recapContainer {
  width: 70vw;
}

.code-priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.couponSummary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
}

.code {
  color: base.$brand-color;
  font-family: 'RobotoBold';
  margin-top: 30px;
  margin-bottom: 5px;
}

.promoForm {
  display: flex;
  width: 530px;
  justify-content: space-between;
  align-items: flex-end;
}

.deleteCoupon {
  margin-right: -40px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: 5px;
  @extend .genericClickable;
}

.deleteCoupon img {
  height: 20px;
  margin-top: 4px;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 40px;
  width: 35%;
}

.price .price-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

.price p {
  font-family: 'RobotoBold';
  margin: 0;
}

.modifyIcon {
  margin-left: 8px;
  background-color: base.$brand-color;
  border-radius: 5px;
  @extend .genericClickable;
}
