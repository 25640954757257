@use './../base';

.buttonText {
    &.linkStyle {
        text-decoration: underline;
    }
    font-family: "RobotoMedium";
    font-size: 14px;
    padding: 4px;
    text-align: right;
    @extend .genericClickable;
}