@use '../base';

.mainContainer {
  width: 100%;
  min-height: 99.8vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.titleAndvideoTutoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-self: flex-start;
}

.headerLayoutContainer {
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.33);
  padding: 20px;
}

.footerLayoutContainer {
  width: 100%;
  height: 110px;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
}

.welcome {
  font-family: 'RobotoBold';
  font-size: 24px;
  color: base.$brand-color;
}

.headerButtons {
  display: flex;
  width: 200px;
  justify-content: flex-end;
}

.headerButtons button {
  background-color: transparent;
  border: none;
  @extend .genericClickable;
}

.headerPicto {
  height: 35px;
  margin-bottom: 5px;
}

.pictoSubtitle {
  color: base.$brand-color;
  font-family: 'RobotoBold';
  font-size: 12px;
  width: 90px;
  margin: 0;
}

.voucherTitle {
  color: base.$brand-color;
  font-family: 'RobotoBold';
  font-size: 14px;
  width: 120px;
  margin-top: 2px;
  margin-bottom: 0;
}

.conditionButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'RobotoLight';
  padding: 0;
}

.conditionButtons a {
  text-decoration: none;
  color: inherit;
  font-size: 16;
  @extend .genericClickable;
}

.separateButtonsText {
  margin: 0 1em;
}

.rightsReserved {
  font-size: 10px;
  font-family: 'RobotoLight';
  text-align: center;
  margin-bottom: 0;
}

.languageButtons {
  width: 165px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 20px;
}

.languageButtons button {
  height: 25px;
  padding-left: 10px;
  border-style: none;
  background-color: transparent;
  @extend .genericClickable;
}

.ambassadorBox {
  margin: 10px 0 0 20px;
  height: 142px;
  background: url('../assets/icons/encarsAmbassadeurs.svg') no-repeat center;
  background-size: contain;
  text-align: center;
  font-size: 13px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ambassadorNestedText {
  width: 70%;
  margin-top: 8px;
}
