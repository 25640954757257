@use './../base';

#CheckBoxList {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: start;
}

#CheckBoxList > div{
    align-items: start;
    display: flex;
    flex-direction: column;
}

#CheckBoxList > div > div{
    margin-top: -10%;
}