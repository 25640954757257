@use './../base';

.campaignCard {
  width: 250px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
}

.campaignPreview {
  width: 200px;
  height: 200px;
  padding: 10px;
  overflow: hidden;
  background-color: whitesmoke;
  border-radius: 10px;
  border: 2px solid base.$grey-color;
  @extend .genericClickable;
}

.adPreview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.adPreview > section {
  padding-bottom: 5px;
}

.sub {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0;
  padding-top: 8px;
}

.sub img {
  height: 18px;
  padding-right: 5px;
}

.sub p {
  margin: 0;
}

.campaignButtonContainer {
  width: 220px;
  display: flex;
  justify-content: space-around;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10px;
}
