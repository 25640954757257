@use '../base';

.radioContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.video {
  width: 280px;
  margin-top: 60px;
}

.radioContainer p {
  display: block;
  font-size: 12px;
  text-align: center;
  color: grey;
  margin: 0;
  padding-top: 5px;
  align-self: center;
}
