@use './../base';

.annonce {
  height: 470px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.adButtonContainer {
  width: 220px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  justify-self: flex-end;
}

.viewsContainer {
  width: 220px;
}

.views {
  font-family: 'RobotoRegular';
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
}
